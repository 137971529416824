<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap justify-center>
      <v-flex lg11>

      

    <v-layout wrap px-5 pt-10>
      <v-flex xs12 sm7 md7 lg7 text-left>
        <span style="color: #000; font-family: Montserrat-Bold; font-size: 28px"
          >User List</span
        >
      </v-flex>

      <v-flex xs12 sm2 md3 lg3 text-center align-self-center>
        <!-- <span style="color: #000; font-family: Lato-Regular; font-size: 16px"
          >Total Users : {{totalUsers}}</span
        > -->
      </v-flex>

      <v-flex xs12 sm3 md2 lg2>
        <v-select
          dense
          :items="items"
          v-model="userType"
          label="Sign Up Method"
          outlined
          item-text="name"
          item-value="value"
          hide-details="auto"
        ></v-select>
      </v-flex>
      <!-- <v-flex xs12 sm2 md2 text-right pa-2>
        <v-btn
          tile
          class="px-5"
          small
          outlined
          :href="ipURL + '/admin/user/list/xlsx'"
          target="_blank"
          color="warning"
          style="text-transform: none"
        >
          <span
            style="font-size: 14px; font-family: poppinssemibold; color: #000"
          >
            Download Excel</span
          >
        </v-btn>
      </v-flex> -->
    </v-layout>
    <v-layout wrap pt-5 px-5>
      <v-flex xs12>
        <v-data-table
  :headers="headers"
  :items="user"
  :items-per-page="limit"
  hide-default-footer
  class="elevation-1 .text--black"
  id="virtual-scroll-table"
>
  <template v-slot:[`item.create_date`]="{ item }">
    <span v-if="item.create_date">{{ item.create_date.slice(0, 10) }}</span>
  </template>
  <template v-slot:[`item.signUpMethod`]="{ item }">
    <span v-if="item.signUpMethod == 'User'">Student</span>
    <span v-else>{{ item.signUpMethod }}</span>
  </template>
  <template v-slot:[`item.phone`]="{ item }">
    <span v-if="item.phone">{{ item.countryCode }} {{ item.phone }}</span>
  </template>
  <template v-slot:[`item.userSubscriptionPlanId.planName`]="{ item }">
    <span v-if="item.userSubscriptionPlanId && item.userSubscriptionPlanId.planName">
      {{ item.userSubscriptionPlanId.planName }}
    </span>
    <v-flex lg12  v-else>
      <span >
      N/A
    </span>
    </v-flex>
    
  </template>
  <template v-slot:[`item._id`]="{ item }">
    <span @click="info(item)" style="cursor: pointer;">View &nbsp;
    <v-icon small class="mr-2" >mdi-eye</v-icon>
  </span>
  </template>
</v-data-table>

      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#00A08E"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

  </v-flex>
</v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 20,
      totalUsers: "",
      user: [],
      items: [
        { name: "All", value: "All" },
        { name: "Email", value: "Email" },
        { name: "Apple", value: "Apple" },
        { name: "Google", value: "Google" },
      ],
      userType: "",
      keyword: "",
      headers: [
  // { text: "SL.No", value: "index", width: "10px" },
  { text: "Name", value: "name", width: "140px" },
  { text: "Email", value: "email", width: "90px" },
  { text: "Plan", value: "userSubscriptionPlanId.planName", width: "90px" },
  { text: "Phone", value: "phone", width: "80px" },
  { text: "Sign In", value: "signUpMethod", width: "70px" },
  { text: "Date", value: "create_date", width: "80px" },
  { text: "Actions", value: "_id", width: "30px" },
],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    userType() {
      this.getData();
      this.currentPage=1;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if(!this.userType){
            this.userType = this.$route.query.method
        }

      this.appLoading = true;
      axios({
        url: "/admin/users/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          
          signUpMethod: this.userType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.totalUsers = response.data.totalLength;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(id) {
      this.$router.push("/userDetails?id=" + id._id);
    },
  },
};
</script>
<style>
.v-data-table-header {
  background-color: #00A08E;
  font-size: 20px;
  color: white;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: white;
  font-family: poppinsmedium;
}

.v-data-table tr td {
  font-size: 13px !important;
  color: black;
  font-family: poppinsmedium;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>