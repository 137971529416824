<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <v-layout wrap justify-start class="my-3 ml-2">
          <v-flex
            xs12
            sm3
            md3
            lg2
            text-start
            align-center
            pt-2
            class="headingChurch"
          >
            <span
              style="
                font-family: Montserrat-Bold;
                font-size: 28px;
                font-weight: 600;
              "
              >Dashboard</span
            >
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToSubPlans()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-cash-multiple</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Subscription Plans</div>
                      <div
                        style="
                          font-family: Lato-Regular;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        {{ count.subscriptionPlans }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToRegions()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-select-marker</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Regions</div>
                      <div
                        style="
                          font-family: Lato-Regular;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        {{ count.regions }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToCountries()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-map-marker</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Countries</div>
                      <div
                        style="
                          font-family: Lato-Regular;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        {{ count.countries }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToEmail()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-email</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Email Users</div>
                      <div
                        style="
                          font-family: Lato-Regular;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        {{ count.emailUsers }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToApple()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-apple</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Apple Users</div>
                      <div class="sub-count">{{ count.appleUsers }}</div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card
                  class="mainbg1 text-left pa-6 elevation-1"
                  rounded="xl"
                  @click="navigateToGmail()"
                >
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="80" class="white">
                        <v-icon large color="#00A08e">mdi-gmail</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      xs12
                      lg9
                      pl-3
                      style="
                        font-family: Montserrat-Regular;
                        font-size: 21px;
                        font-weight: 600;
                      "
                    >
                      <div>Google Users</div>
                      <div
                        style="
                          font-family: Lato-Regular;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        {{ count.googleUsers }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm6 pa-2>
                <v-card rounded="xl">
                  <!-- <v-img :src="require()">
  
                      <v-layout column align-left pa-4 pl-6 pt-6 style="color: white; font-family:Montserrat-Regular';">
                        <v-flex xs12 class="font-weight-medium" style="font-size: 20px;">
                          Live Masses around the world
                        </v-flex>
  
                        <v-flex xs12 class="mt-2" style="font-size: 28px;">
                          14.5 K
                        </v-flex>
  
                        <v-flex xs12 class="mt-2" style="font-size: 12px;">
                          “I can do all this through him who gives me strength.”
                          <v-flex xs11 sm11 md10 lg8 pt-2 text-end>
                            <div>
                              Philippians 4:13
                            </div>
                          </v-flex>
                         
                        </v-flex>
                      </v-layout>
  
                    </v-img> -->
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      count: {},
      addRegionDialog: false,
      regionName: "",
      editRegionDialog: false,
      editRegionName: "",
      regionId: "",
    };
  },
  mounted() {
    this.getDashboard();
  },
  methods: {
    getDashboard() {
      this.appLoading = true;
      axios({
        url: "/admin/dashboard",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.count = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    navigateToSubPlans() {
      this.$router.push({ path: "/subcategory" });
    },
    navigateToRegions() {
      this.$router.push({ path: "/banner" });
    },
    navigateToCountries() {
      this.$router.push({ path: "/Country" });
    },
    navigateToEmail() {
      this.$router.push({ path: "/userList", query: { method: "Email" } });
    },
    navigateToApple() {
      this.$router.push({ path: "/userList", query: { method: "Apple" } });
    },
    navigateToGmail() {
      this.$router.push({ path: "/userList", query: { method: "Google" } });
    },
  },
};
</script>

<style>
/* .mainbg1 {
  background-color: #FC6736;
  padding: 5px;
} */

.mainbg1 {
  background-image: linear-gradient(to right, #efefef, #efefef);
  padding: 5px;
}

.mainbg3 {
  background-image: linear-gradient(to right, #970697c6, #7e7e7edd);
  padding: 5px;
}
</style>