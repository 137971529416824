<template>
  <v-navigation-drawer v-model="Sidebar_drawer" :dark="SidebarColor !== 'white'" :color="SidebarColor"
    mobile-break-point="960" clipped :right="$vuetify.rtl" mini-variant-width="70" :expand-on-hover="expandOnHover" app
    id="main-sidebar">
    <v-list dense nav>
      
      <v-list-item v-for="item in filteredAdminItems" :key="item.title" :to="item.to"  :active-class="` white--text`" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  // props: {
  //   expandOnHover: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data: () => ({
    userRole: localStorage.getItem("role"),
    adminitems: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard",
      },
      {
        title: "User List",
        icon: "mdi-account -circle",
        to: "/userList",
      },
      {
        title: "Region",
        icon: "mdi-select-marker",
        to: "/banner"
      },
      {
        title: "Country",
        icon: "mdi-map-marker",
        to: "/Country",
      },
      {
        title: "Subscription",
        icon: "mdi-cash-multiple",
        to: "/subcategory",
      },
      {
        title: "Voucher",
        icon: "mdi-ticket-percent",
        to: "/voucher",
      },
      {
        title: "Contact Us",
        icon: "mdi-mail",
        to: "/Messages",
      },{
        title: "Change Password",
        icon: "mdi-cog",
        to: "/Messages",
        xsOnly: true, 
      },{
        title: "Logout",
        icon: "mdi-logout",
        to: "/Messages",
        xsOnly: true, 
      },
     
    ],
    
    Home: [
      { title: "Banner", to: "/banner" },
    ],

  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
    filteredAdminItems() {
      const isXsScreen = this.$vuetify.breakpoint.xsOnly;
      return this.adminitems.filter(item => !item.xsOnly || (item.xsOnly && isXsScreen));
    },
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {},
};
</script>
<style lang="scss">
.custom-active-class {
  background-color: #00A08E;
  color: white;
}
.v-list-item--active {
  background-color: #00A08E;
}
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {

    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }

  .success {
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
  }
}
</style>
